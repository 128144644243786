import { useEffect, useRef, useState } from "react";
import "./styles.css";
import { findUserSelectedParams } from "../../utils/FindUserSelectedParams";
import {
  clearAllSelectedFiltersForDashbooard,
  handleParentFilterUnchecked,
  generateParamString,
  FindChildFilterSubElements,
  updateUserSelectedFilter,
  FilterSubOptions,
} from "../../utils/clearAllSelectedFiltersForDashboard";
import {
  capitalizeFirstLetter,
  displaySelectedFilters,
  handleChildFilterUnchecked,
  handleParentClick,
  removeCheckFromAllSuboptions,
  storedFilters,
  updateUrlWithFilters,
} from "../../utils/clearAllSelectedFilters";
import {
  findFilterOptionsForDashboard,
  getLoader,
} from "../../utils/DashboardFunctions";
import { getSubFilterClassName } from "../../utils/TicketsTableFunctions";
import { defaultUserSelectedFilter } from "./data";
import { errorNotifyList } from "../../components/ToastComponent";
import { ToastContainer } from "react-toastify";
import { removeDateParamsFromUrl } from "../../components/DashboardCards/CardsFunctions";
import { getFiltersData, getOpenTickets } from "../../services/apiServices";

import OpenTickets from "../../components/OpenTicketsComponent";
import Header from "../../components/Header/index";
import DashboardDateFiltersRows from "../../components/DashboardDateFiltersRows";
import { REACT_APP_API_PATH as baseURL } from "../../config";
import { useURLInfoDashboard } from "../../utils/useURLInfo";
import { handleClickOutsideFilters } from "../../utils/common_functions";

export const DashboardComponent = () => {
  const [allCardsData, setAllCardsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  /* For filters */

  const [allFilters, setAllFilters] = useState(() => {
    const getStoredAllFilters = sessionStorage.getItem("allFilters");
    return getStoredAllFilters ? JSON.parse(getStoredAllFilters) : {};
  });

  const [filterOptions, setFilterOptions] = useState([]);

  const [userSelectedFilter, setUserSelectedFilter] = useState(() => {
    const getStoredFilters = sessionStorage.getItem("storedFilters");
    return getStoredFilters
      ? JSON.parse(getStoredFilters)
      : defaultUserSelectedFilter;
  });

  //For checking if user filters are set or not
  const [isUserFilterSet, setIsUserFilterSet] = useState(false);

  const [parameterString, setParameterString] = useState("");

  const [openTicketsError, setOpenTicketsError] = useState(false);

  const urlUserSelectedParams = useURLInfoDashboard();

  const dashboardInit = useRef(true);

  const checkClearAllVisibility = () => {
    let flag = false;

    Object.keys(userSelectedFilter).forEach((item) => {
      if (
        !["spanKey", "startDate", "endDate"].includes(item) &&
        userSelectedFilter[item]?.length > 0 &&
        item !== "closed tickets" &&
        item !== "open tickets"
      )
        flag = true;
      else if (item === "closed tickets") {
        flag = true;
      } else if (item === "open tickets") {
        flag = true;
      }
    });

    if (flag === true) return "visible";
    else return "hidden";
  };

  const fetchFiltersData = async () => {
    const data = await getFiltersData(baseURL);
    if (data && typeof data === "object") {
      delete data["sla_status"];
      delete data["status"];
    }

    setAllFilters(data);
    sessionStorage.setItem("allFilters", JSON.stringify(data));

    setFilterOptions(
      findFilterOptionsForDashboard(
        data,
        findUserSelectedParams(data),
        urlUserSelectedParams
      )
    );

    setUserSelectedFilter({
      ...userSelectedFilter,
      ...findUserSelectedParams(data),
      ...urlUserSelectedParams,
    });

    setIsUserFilterSet(true);
  };

  const getOpenTicketsParamString = (parameterString) => {
    return `${removeDateParamsFromUrl(
      parameterString.replace("&", "?"),
      "completed_date_after",
      "completed_date_before"
    )}`;
  };

  const fetchDataForCards = async () => {
    setIsLoading(true);
    try {
      const paramStringForOpenTickets =
        getOpenTicketsParamString(parameterString);

      const response = await getOpenTickets(paramStringForOpenTickets, baseURL);

      if (response.data.length === 0)
        throw new Error("No data found for open SLA tickets");

      setAllCardsData(response.data);
      setTotalCount(response.total_count);
      setOpenTicketsError(null);
    } catch (error) {
      errorNotifyList([`Open SLA tickets HTTP error. ${error}`]);
      setOpenTicketsError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const showFiltersDropdown = () => {
    const elem = document.getElementsByClassName("filters_dropdown")[0];
    elem.classList.toggle("show");
  };

  const handleHideAllSuboptions = (e) => {
    setFilterOptions(
      filterOptions.map((filterOption) => {
        return {
          ...filterOption,
          isHighlighted: false,
          showsuboptions: false,
        };
      })
    );
  };

  const handleChildClick = (
    e,
    parentFilterType,
    childFilterId,
    parentFilterIndex,
    childFilterIndex,
    sub,
    childFilterType
  ) => {
    e.stopPropagation();

    updateUserSelectedFilter({
      e,
      parentFilter: {
        parentFilterType,
        childFilterId,
        childFilterType,
      },
      indices: {
        parentFilterIndex,
        childFilterIndex,
      },
      sub,
      callbacks: {
        handleChildFilterUnchecked,
        setFilterOptions,
        setUserSelectedFilter,
      },
      state: {
        userSelectedFilter,
        filterOptions,
      },
      setParameterString,
      generateParamString,
    });
  };

  const filterFunction = (e) => {
    let input, filter, a;
    input = e.target;

    filter = input.value.toUpperCase();

    let div = document.getElementsByClassName(
      "filter_dropdown_child_options"
    )[0];

    a = div.getElementsByClassName("sub_filter_checkbox");

    for (let elem of a) {
      let txtValue = elem.textContent || elem.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        elem.style.display = "";
      } else {
        elem.style.display = "none";
      }
    }
  };

  const handleMouseOver = (e, index, filter) => {
    if (filter.type !== "closed tickets" || filter.type !== "open tickets") {
      handleParentClick(
        e,
        index,
        filter,
        filterOptions,
        setFilterOptions,
        userSelectedFilter,
        setUserSelectedFilter
      );
    } else {
      handleHideAllSuboptions();
    }
  };

  useEffect(() => {
    if (dashboardInit.current === true) {
      fetchFiltersData();
      dashboardInit.current = false;
    }
  }, []);

  useEffect(() => {
    setParameterString(generateParamString(userSelectedFilter, null, false));

    setFilterOptions(
      findFilterOptionsForDashboard(
        allFilters,
        storedFilters(userSelectedFilter)
      )
    );
    updateUrlWithFilters(
      userSelectedFilter,
      userSelectedFilter["spanKey"],
      userSelectedFilter["startDate"],
      userSelectedFilter["endDate"]
    );
  }, [userSelectedFilter]);

  useEffect(() => {
    if (isUserFilterSet) {
      fetchDataForCards();
    }
  }, [parameterString, isUserFilterSet]);

  useEffect(() => {
    const cleanup = handleClickOutsideFilters("filters_dropdown");

    return () => {
      cleanup();
    };
  }, []);

  return (
    <div className="dashboardComponent">
      <div className="header">
        <Header
          heading="Ticket Metrics Dashboard"
          showFiltersDropdown={() => showFiltersDropdown()}
          filterOptions={filterOptions}
          onMouseOver={handleMouseOver}
          handleParentFilterUnchecked={handleParentFilterUnchecked}
          parentFilterOptions={capitalizeFirstLetter}
          FilterSubOptions={FilterSubOptions}
          getSubFilterClassName={getSubFilterClassName}
          onKeyUp={filterFunction}
          handleChildClick={handleChildClick}
          setFilterOptions={setFilterOptions}
          userSelectedFilter={userSelectedFilter}
          setUserSelectedFilter={setUserSelectedFilter}
          FindChildFilterSubElements={FindChildFilterSubElements}
          displaySelectedFilters={displaySelectedFilters(
            userSelectedFilter,
            capitalizeFirstLetter,
            allFilters,
            setUserSelectedFilter,
            filterOptions,
            setFilterOptions
          )}
          checkClearAllVisibility={`${checkClearAllVisibility()}`}
          clearAllSelectedFilters={() =>
            clearAllSelectedFiltersForDashbooard(
              setUserSelectedFilter,
              setFilterOptions,
              filterOptions,
              removeCheckFromAllSuboptions
            )
          }
          searchFieldStyle={{
            display: "none",
          }}
          style={{
            display: "none",
          }}
          exportButtonDisplay="none"
          buttonDisplay="none"
        />
      </div>
      <div className="content">
        <OpenTickets
          isLoading={isLoading}
          getLoader={getLoader}
          openTicketsError={openTicketsError}
          allCardsData={allCardsData}
          totalCount={totalCount}
          parameterString={parameterString}
        />

        <DashboardDateFiltersRows
          parameterString={parameterString}
          filterOptions={filterOptions}
          isUserFilterSet={isUserFilterSet}
          userSelectedFilter={userSelectedFilter}
        />
      </div>
    </div>
  );
};

const Dashboard = () => {
  return (
    <>
      <DashboardComponent />
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default Dashboard;

import { useEffect, useState } from "react";
import {
  getSpaceData,
  getFolderData,
  getListData,
  getListWithoutFolderData,
} from "../../services/apiServices";
import {
  AUTOMATIONS_API_PATH as automationBaseURL,
  REACT_APP_API_PATH as baseURL,
} from "../../config";
import AutomationsTable from "../../components/AutomationsTable";
import {
  customAPICallWithPayload,
  customFetch,
} from "../../services/requestServices";
import { successNotify, errorNotify } from "../../components/ToastComponent";
import { ToastContainer } from "react-toastify";

const Automation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const url = `${automationBaseURL}rules`;
  const [spaces, setSpaces] = useState([]);
  const [folders, setFolders] = useState([]);
  const [lists, setLists] = useState([]);
  const [listsWithoutFolder, setListsWithoutFolder] = useState([]);
  const [openItems, setOpenItems] = useState({});

  const fetchRulesData = async () => {
    setIsLoading(true);

    const data = await customFetch(url, "GET", null);
    setRowData(data);
    setIsLoading(false);
  };

  const deleteRule = async (id, ruleName) => {
    setIsLoading(true);
    const deleteUrl = `${automationBaseURL}rules/${id}/`;

    const response = await customAPICallWithPayload(deleteUrl, "DELETE", "");

    if (response == "success") {
      successNotify(`${ruleName} deleted successully`);
      fetchRulesData();
    } else errorNotify(response);
  };

  const updatePriority = async (priority_from, priority_to) => {
    setIsLoading(true);

    const updatePriorityUrl = `${automationBaseURL}rules/update-priority/`;

    const response = await customAPICallWithPayload(
      updatePriorityUrl,
      "PATCH",
      {
        priority_from: priority_from,
        priority_to: priority_to,
      }
    );

    if (response === "success") {
      successNotify(`Priority updated successfully.`);
      fetchRulesData();
    } else {
      errorNotify(`Failed to update priority. Please try again.`);
    }

    setIsLoading(false);
  };

  const updateEnableStatus = async (id, enableStatus) => {
    setIsLoading(true);

    const updateUrl = `${automationBaseURL}rules/${id}/`;

    const response = await customAPICallWithPayload(updateUrl, "PATCH", {
      enable: enableStatus,
    });

    if (response === "success") {
      successNotify(`Rule status updated successfully.`);
      fetchRulesData();
    } else errorNotify(`Failed to update rule status. Please try again.`);

    setIsLoading(false);
  };

  const enableList = async (id, enabledList) => {
    setIsLoading(true);

    const updateUrl = `${automationBaseURL}rules/${id}/`;

    const response = await customAPICallWithPayload(updateUrl, "PATCH", {
      enable_on_list: enabledList,
    });

    if (response === "success") {
      successNotify(`List enabled successfully.`);
      fetchRulesData();
    } else errorNotify(`Failed to enable list. Please try again.`);

    setIsLoading(false);
  };

  const fetchSpaceData = async () => {
    const response = await getSpaceData(baseURL);

    setSpaces(response);
    setIsLoading(false);
  };

  const fetchFolderData = async (spaceId) => {
    const response = await getFolderData(spaceId, baseURL);

    setFolders((prevFolders) => ({ ...prevFolders, [spaceId]: response }));
    setIsLoading(false);
  };

  const fetchListData = async (folderId) => {
    const response = await getListData(folderId, baseURL);

    setLists((prevLists) => ({ ...prevLists, [folderId]: response }));
    setIsLoading(false);
  };

  const fetchListWithoutFolderData = async (spaceId) => {
    const response = await getListWithoutFolderData(spaceId, baseURL);

    setListsWithoutFolder((prevListsWithoutFolder) => ({
      ...prevListsWithoutFolder,
      [spaceId]: response,
    }));
    setIsLoading(false);
  };

  const handleItemClick = (itemId) => {
    setOpenItems({ ...openItems, [itemId]: !openItems[itemId] });
  };

  useEffect(() => {
    fetchRulesData();
  }, []);

  return (
    <>
      <AutomationsTable
        rulesData={rowData}
        isLoading={isLoading}
        spaces={spaces}
        folders={folders}
        lists={lists}
        listsWithoutFolder={listsWithoutFolder}
        fetchRulesData={fetchRulesData}
        deleteRule={deleteRule}
        updatePriority={updatePriority}
        updateEnableStatus={updateEnableStatus}
        enableList={enableList}
        fetchSpaceData={fetchSpaceData}
        fetchFolderData={fetchFolderData}
        fetchListData={fetchListData}
        fetchListWithoutFolderData={fetchListWithoutFolderData}
        handleItemClick={handleItemClick}
        openItems={openItems}
      />
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default Automation;

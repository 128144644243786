import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

export const iconsSuperUser = [
  {
    icon_name: "Dashboard",
    icon: DashboardOutlinedIcon,
    link: "/dashboard",
  },
  {
    icon_name: "Tickets",
    icon: ConfirmationNumberOutlinedIcon,
    link: "/tickets",
  },
  {
    icon_name: "Teams",
    icon: GroupsOutlinedIcon,
    link: "/teams",
  },
  {
    icon_name: "Users",
    icon: PersonOutlineIcon,
    link: "/users",
  },
];

export const iconsNonGuardianrUser = [
  {
    icon_name: "Tickets",
    icon: ConfirmationNumberOutlinedIcon,
    link: "/tickets",
  },
];

export const iconsGuardianUser = [
  {
    icon_name: "Dashboard",
    icon: DashboardOutlinedIcon,
    link: "/dashboard",
  },
  {
    icon_name: "Tickets",
    icon: ConfirmationNumberOutlinedIcon,
    link: "/tickets",
  },
  {
    icon_name: "Users",
    icon: PersonOutlineIcon,
    link: "/users",
  },
];

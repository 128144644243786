import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Skeleton, TextField } from "@mui/material";
import "./style.css";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import SaveIcon from "@mui/icons-material/Save";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import { ClickUpList } from "../ClickUpList";
import { styled } from "@mui/material/styles";
import _without from "lodash/without";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const columns = [
  { id: "name", label: "name", width: 100 },
  { id: "custom_list_id", label: "options", width: 100 },
  { id: "custom_list_id", label: "Clickup list id", width: 70 },
  { id: "action", label: "", width: 50 },
];
const toolTipText = {
  name: "Name of the sub team",
  options: "Dropdown options of the selected sub team custom field",
  "Clickup list id": "Clickup list id",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f5f5f5",
  },
}));
const AutoCompleteDropdown = ({
  dropDownData,
  handleNewCellValue,
  setAutoComplete,
  autoComplete,
}) => {
  return (
    <Autocomplete
      className="subTeamInputBox"
      disablePortal={true}
      disableClearable
      id="combo-box-demo"
      options={dropDownData}
      getOptionLabel={(options) => options.name}
      // disabled={!edit_permission()}
      value={autoComplete || ""}
      sx={{
        width: "17vw",
        "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
          fontSize: "14px",
        },
      }}
      // loading={true}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
        />
      )}
      renderOption={(props, option) => (
        <MenuItem
          key={option.id}
          value={option.id}
          sx={{
            padding: "5px 5px",
            whiteSpace: "unset",
            wordBreak: "break-all",
          }}
          onClick={(e) => {
            setAutoComplete(option);
            handleNewCellValue(option, "custom_id");
          }}
        >
          <Radio
            size="small"
            checked={option.name == autoComplete.name}
            sx={{
              color: "#C0C3CE",
              "&.Mui-checked": {
                color: "#7C4DFF",
              },
            }}
          />
          <ListItemText
            disableTypography
            primary={option.name}
            sx={{ fontSize: "13px", color: "#3C3E49" }}
          />
        </MenuItem>
      )}
    />
  );
};

const TableCellsWithActions = ({
  row,
  color_id,
  handleDeleteRow,
  dropdownOptions,
  edit_permission,
}) => {
  const getCustomIdName = (id) => {
    let name = !["", undefined, null].includes(dropdownOptions)
      ? dropdownOptions.filter((item) => item.id == id)
      : [];
    return name.length == 0 ? "" : name[0]["name"];
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#F8F9FA",
    },
  }));
  return (
    <StyledTableRow key={row.custom_id}>
      <TableCell className="tablecellstyleName"> {row.name} </TableCell>
      <TableCell className="tablecellstyle">
        {" "}
        {getCustomIdName(row.custom_id)}
      </TableCell>
      <TableCell className="tablecellstyle">{row.custom_list_id}</TableCell>
      <TableCell
        className="tablecellstyle"
        align="center"
        sx={{ "& :hover": { backgroundColor: "#F3EBFE" } }}
      >
        <Button
          disabled={!edit_permission()}
          onClick={() => handleDeleteRow(row.custom_id, row)}
          sx={{
            border: "1px solid rgba(179, 136, 255, 0.30)",
            minWidth: "auto",
          }}
        >
          <DeleteOutlineIcon
            style={{ color: "#3b3e49", opacity: 0.7 }}
            fontSize="small"
          />
        </Button>
      </TableCell>
    </StyledTableRow>
  );
};
const EditableCell = ({ field, handleNewCellValue }) => {
  return (
    <TextField
      sx={{
        "& .MuiOutlinedInput-root": {
          maxHeight: "36px !important",
          fontSize: "14px",
          color: "#3c3e49",
          textAlign: "center",
          width: "17vw",
        },
      }}
      onChange={(e) => handleNewCellValue(e, field)}
    />
  );
};

const AddEditRow = ({
  handleAddRow,
  handleNewCellValue,
  checkForFields,
  dropDownData,
  setDropdownOptions,
  setAutoComplete,
  autoComplete,
  spaces,
  folders,
  lists,
  listsWithoutFolder,
  fetchSpaceData,
  fetchFolderData,
  fetchListData,
  fetchListWithoutFolderData,
  handleItemClick,
  openItems,
  customID,
}) => {
  return (
    <TableRow>
      <TableCell className="tablecellstyle">
        {" "}
        <EditableCell
          handleNewCellValue={handleNewCellValue}
          field={"name"}
          checkForFields={checkForFields}
        />
      </TableCell>
      <TableCell className="tablecellstyle">
        {" "}
        <AutoCompleteDropdown
          dropDownData={dropDownData}
          setDropdownOptions={setDropdownOptions}
          setAutoComplete={setAutoComplete}
          autoComplete={autoComplete}
          handleNewCellValue={handleNewCellValue}
        />{" "}
      </TableCell>
      <TableCell className="tablecellstyleClickup">
        {" "}
        <ClickUpList
          displayMode="modal"
          component={"sub_team"}
          access_type={"add"}
          spaces={spaces}
          folders={folders}
          lists={lists}
          listsWithoutFolder={listsWithoutFolder}
          fetchSpaceData={fetchSpaceData}
          fetchFolderData={fetchFolderData}
          fetchListData={fetchListData}
          fetchListWithoutFolderData={fetchListWithoutFolderData}
          handleItemClick={handleItemClick}
          openItems={openItems}
          customID={""}
          setClickupList={(value) =>
            handleNewCellValue(value, "custom_list_id")
          }
          textAlign={"left"}
          displayValue = "id"
        />
      </TableCell>
      <TableCell className="tablecellstyle" align="center">
        <Button
          disabled={checkForFields()}
          onClick={() => handleAddRow()}
          sx={{
            border: "1px solid rgba(179, 136, 255, 0.30)",
            minWidth: "auto",
          }}
        >
          <SaveIcon
            color={checkForFields() ? "disabled" : "secondary"}
            fontSize="small"
          />
        </Button>
      </TableCell>
    </TableRow>
  );
};

export const SubTeamTable = ({
  subTeams,
  updateOrCreateSubTeam,
  page,
  deactivateSubTeam,
  isLoading,

  subTeamcustomField,
  edit_permission,
  disabledParentDropdown,
  dataForPutApi,

  spaces,
  folders,
  lists,
  listsWithoutFolder,
  fetchSpaceData,
  fetchFolderData,
  fetchListData,
  fetchListWithoutFolderData,
  handleItemClick,
  openItems,
  customID,
}) => {
  const [data, setData] = React.useState(subTeams);
  const [addRow, setAddRow] = React.useState(false);
  const [newData, setnewData] = React.useState({
    name: "",
    custom_id: "",
    custom_list_id: "",
    team: Number(page),
    is_active: "true",
  });

  const [dropdownOptions, setDropdownOptions] = React.useState(
    subTeamcustomField.type_config.options
  );

  const [autoComplete, setAutoComplete] = React.useState({ id: "", name: "" });

  React.useEffect(() => {
    setData(subTeams);
    setDropdownOptions(subTeamcustomField.type_config.options);
  }, [subTeams.length, subTeamcustomField.name]);

  const handleDeleteRow = (custom_id, row) => {
    setData((current) => _without(current, row));
    deactivateSubTeam(row);
    disabledParentDropdown(_without(data, row));
  };

  const addRowToTable = () => {
    setAddRow(!addRow);
    setAutoComplete({ id: "", name: "" });
  };

  const handleNewCellValue = (event, field) => {
    if (field == "custom_id") setnewData({ ...newData, [field]: event.id });
    if (field == "custom_list_id") setnewData({ ...newData, [field]: event });
    if (field == "name")
      setnewData({ ...newData, [field]: event.target.value });
  };
  const checkForFields = () => {
    return !(newData["name"] && newData["custom_id"]);
  };

  const handleAddRow = () => {
    updateOrCreateSubTeam(newData, {
      ...dataForPutApi,
      sub_team_custom_field: subTeamcustomField.id,
    });

    setData([...data, newData]);
    setAddRow(!addRow);
    setnewData({
      name: "",
      custom_id: "",
      custom_list_id: "",
      team: Number(page),
      is_active: "true",
    });
    setAutoComplete({ id: "", name: "" });
    disabledParentDropdown([...data, newData]);
  };

  return (
    <>
      <Grid
        sx={{ justifyContent: "flex-end", display: "grid", marginTop: "-3rem" }}
      >
        <Button
          className={
            !edit_permission() ? "addSubTeamButtonDisabled" : "addSubTeamButton"
          }
          onClick={addRowToTable}
          disabled={!edit_permission()}
        >
          <AddIcon fontSize="small" color={"#3e3c49"} />
          Add sub team
        </Button>
      </Grid>

      <TableContainer
        sx={{ maxHeight: "20rem", overflow: "auto" }}
        className={!edit_permission() ? "disabledTabled" : "tableContainer"}
      >
        <Table size="small" stickyHeader>
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              "& .MuiTableCell-root": { backgroundColor: "white" },
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.label}
                  align={column.align}
                  width={column.width}
                  className="tablecellstyleHeader"
                >
                  {["name", "options"].includes(column.label) ? (
                    <span className="mandatoryAsterik">*</span>
                  ) : (
                    <></>
                  )}

                  {column.label}
                  <span>
                    <Tooltip title={toolTipText[column.label]}>
                      <InfoOutlinedIcon
                        sx={{
                          // paddingTop: "5px",
                          fontSize: "12px",
                          color: ["action"].includes(column.id)
                            ? "transparent"
                            : "",
                        }}
                      />
                    </Tooltip>
                  </span>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {addRow && (
              <AddEditRow
                handleAddRow={handleAddRow}
                handleNewCellValue={handleNewCellValue}
                checkForFields={checkForFields}
                dropDownData={dropdownOptions}
                setDropdownOptions={setDropdownOptions}
                setAutoComplete={setAutoComplete}
                autoComplete={autoComplete}
                spaces={spaces}
                folders={folders}
                lists={lists}
                listsWithoutFolder={listsWithoutFolder}
                fetchSpaceData={fetchSpaceData}
                fetchFolderData={fetchFolderData}
                fetchListData={fetchListData}
                fetchListWithoutFolderData={fetchListWithoutFolderData}
                handleItemClick={handleItemClick}
                openItems={openItems}
                customID={customID}
              />
            )}
            {data.map((row, id) => {
              return isLoading ? (
                <Skeleton width={"80vw"} height={"5vh"} />
              ) : (
                <TableCellsWithActions
                  key={row.custom_id}
                  color_id={id}
                  row={row}
                  handleDeleteRow={handleDeleteRow}
                  dropdownOptions={dropdownOptions}
                  edit_permission={edit_permission}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

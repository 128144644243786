import React from "react";
import {
  Box,
  FormControl,
  TextField,
  MenuItem,
  Typography,
} from "@mui/material";
import { capitalizeFirstLetter } from "../../utils/clearAllSelectedFilters";
import RenderDynamicInput from "../DynamicInputs";

const RuleSelectionBox = ({
  ruleName,
  rulesData,
  inputsData,
  valuesData,
  handleChange,
  handleValueChange,
  clickupProps,
  handleClickupList
}) => (
  <Box
    sx={{
      minWidth: 275,
      border: "1px solid #ECEDF0",
      borderRadius: "4px",
      padding: "1rem",
      width: "80%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: "3rem",
    }}
  >
    <FormControl fullWidth>
      <TextField
        select
        value={ruleName || ""}
        onChange={handleChange}
        sx={{
          fontSize: "14px",
          backgroundColor: "#F9FAFB",
          "& .MuiOutlinedInput-root": {
            maxHeight: "36px !important",
            fontSize: "14px",
          },
          "& .MuiSelect-select span::before": {
            content: '"Select rule"',
            color: "#83889E",
          },
        }}
      >
        {rulesData.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>

    <Typography sx={{ fontSize: "14px", mt: "1rem", mb: "0.5rem" }}>
      {capitalizeFirstLetter(
        rulesData.find((rule) => rule.id === ruleName)?.inputs[0]?.field_name ||
          "value"
      )}
    </Typography>

    <FormControl
      fullWidth
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {inputsData?.map((rule) =>
        valuesData?.map((value, valueIndex) => (
          <RenderDynamicInput
            key={valueIndex}
            inputType={rule.type || ""}
            inputName={rule.value || ""}
            ruleValues={value || []}
            handleInputChange={(event) => handleValueChange(event, valueIndex)}
            clickupProps={clickupProps}
            handleClickupList={(list) => handleClickupList(list, valueIndex)}
          />
        ))
      )}
    </FormControl>
  </Box>
);

export default RuleSelectionBox;

import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";

dayjs.extend(utc);
dayjs.extend(timezone);

const CustomTimePicker = ({
  views,
  value,
  handleTimePickerChange,
  sx,
  slots,
  onAccept,
  label,
  renderInput,
  placeholder,
  disabled,
  shouldShow,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        views={views}
        value={shouldShow ? value : null}
        onChange={handleTimePickerChange}
        sx={sx}
        slots={slots}
        slotProps={{
          actionBar: { actions: ["accept"] },
          textField: { placeholder: placeholder },
        }}
        onAccept={onAccept}
        label={label}
        renderInput={renderInput}
        placeholder={placeholder}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};

export default CustomTimePicker;

import { ErrorBoundary } from "react-error-boundary";
import { ErrorComponent } from "../../pages/Error";
import { logout } from "../../services/requestServices";

const FallbackComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        margin: "20rem",
      }}
    >
      <ErrorComponent
        errorMessage="Oops! something went wrong.Please reach out to Guardian support team"
        handleRefresh={logout}
        firstButtonText="Login again"
      />
    </div>
  );
};

const FallBackErrorComponent = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={(error, errorInfo) => {
        console.log("Error caught!!");
        console.error(error);
        console.error(errorInfo);
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default FallBackErrorComponent;

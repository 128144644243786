export const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  window.location = "/";
};

export const ErrorList = (errorData) => {
  const capitalAndNoUnderscore = (field) =>
    field.replace(/(^|_)(\w)/g, function ($0, $1, $2) {
      return ($1 && " ") + $2.toUpperCase();
    });

  if (Array.isArray(errorData)) {
    return Object.values(errorData).map((errorMsg) =>
      capitalAndNoUnderscore(errorMsg)
    );
  }

  const errorMessages = Object.entries(errorData).map(
    ([field, messages]) =>
      capitalAndNoUnderscore(field) + ":" + capitalAndNoUnderscore(messages[0])
  );
  return errorMessages;
};
export const customFetch = async (url, method, signal = null) => {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        accept: "application/json",
        Authorization: `Token ${localStorage.getItem("access_token")}`,
      },
      signal: signal,
    });

    if (response.status === 401) {
      logout();
      console.log("User is not authenticated. Logging out...");
    } else {
      return await response.json();
    }
  } catch (error) {
    if (error.name === "AbortError") {
      console.log("Ftech Aborted");
      return null;
    } else console.error("Fetch error:", error);
    return error;
  }
};

export const customAPICallWithPayload = async (url, method, data) => {
  const successNotify = "success";
  const errorNotify = "error encountered";

  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        accept: "application/json",
        Authorization: `Token ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.status === 401) {
      logout();
      console.log("User is not authenticated. Logging out...");
    } else if (response.status === 400) {
      console.log("Bad request receieved", response.status);
      return ErrorList(await response.json());
    } else if (response.status === 500) {
      return ["Server Error Please contact admin"];
    } else {
      return successNotify;
    }
  } catch (error) {
    console.error("API error:", error);
    return errorNotify;
  }
};

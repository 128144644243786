export const headCells = [
  {
    "id": "username",
    "label": "Username"
  },
  {
    "id": "squadname",
    "label": "Squad Name"
  },
  {
    "id": "relatedteams",
    "label": "Teams"
  },
  {
    "id": "relatedsubteams",
    "label": "Sub Teams"
  },
  {
    "id": "availability",
    "label": "Availability",
    width:170
  },
  {
    "id": "workingdays",
    "label": "Working Days"
  },
  {
    "id": "workingtime",
    "label": "Working Time"
  },
  {
    "id": "role",
    "label": "Role"
  },
  {
    "id": "actions",
    "label": "Actions"
  }
]
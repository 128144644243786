import { findDashboardDateValue } from "./clearAllSelectedFiltersForDashboard";

const findIfNumericValue = (tempArr) => {
  const newTemp = [];

  for (let temp of tempArr) {
    if (Object.is(parseInt(temp), NaN)) {
      newTemp.push(temp);
    } else newTemp.push(parseInt(temp));
  }

  return newTemp;
};

const completedDate = {
  ["completed_date_after"]: "",
  ["completed_date_before"]: "",
};

export const parseAndSetData = (key, value, data) => {
  switch (true) {
    case key.startsWith("teams"):
      data.teams.push(value);
      break;
    case key.startsWith("sub_teams"):
      data.sub_teams.push(value);
      break;
    case key.startsWith("assignees"):
      data.assignees = value.split(",");
      break;
    case key.startsWith("status"):
      data.status.push(value);
      break;
    case key.startsWith("sla_status"):
      data.sla_status.push(value);
      break;
    default:
      data[key] = value.split(",");
  }
};

export const parseDateRanges = (key, value, data) => {
  switch (key) {
    case "completed_date_before":
      data.closedDate.To = value;
      break;
    case "completed_date_after":
      data.closedDate.From = value;
      break;
    case "created_date_after":
      data.date["Date created"].From = value;
      break;
    case "created_date_before":
      data.date["Date created"].To = value;
      break;
    case "due_date_after":
      data.date["Date due"].From = value;
      break;
    case "due_date_before":
      data.date["Date due"].To = value;
      break;
  }
};

const parseDateRangesDashboard = (key, value, data) => {
  const dateMappings = {
    today: {
      spanKey: "Today",
      startDate: findDashboardDateValue("Today"),
      endDate: findDashboardDateValue("Today"),
    },
    yesterday: {
      spanKey: "Yesterday",
      startDate: findDashboardDateValue("Yesterday"),
      endDate: findDashboardDateValue("Today"),
    },
    last_7_days_from: {
      spanKey: "Last 7 days",
      startDate: findDashboardDateValue("Last 7 days"),
      endDate: findDashboardDateValue("Today"),
    },
    last_14_days_from: {
      spanKey: "Last 14 days",
      startDate: findDashboardDateValue("Last 14 days"),
      endDate: findDashboardDateValue("Today"),
    },
    last_30_days_from: {
      spanKey: "Last 30 days",
      startDate: findDashboardDateValue("Last 30 days"),
      endDate: findDashboardDateValue("Today"),
    },
  };

  if (key.startsWith("custom_date")) {
    if (key === "custom_date_from") {
      data["Custom date"].From = value;
      data["spanKey"] = "Custom date";
      data["startDate"] = value;
    } else if (key === "custom_date_to") {
      data["Custom date"].To = value;
      data["spanKey"] = "Custom date";
      data["endDate"] = value;
    }
  } else if (dateMappings[key]) {
    const { spanKey, startDate, endDate } = dateMappings[key];
    data[spanKey].From = value;
    data["spanKey"] = spanKey;
    data["startDate"] = startDate;
    data["endDate"] = endDate;
  }
};

const parseTicketTypes = (key, value, data) => {
  if (key.startsWith("ticket_type")) {
    if (value === "closed") {
      data["closed tickets"] = true;
    } else if (value === "open") {
      data["open tickets"] = true;
    }
  } else {
    value == "Achieved"
      ? data.first_response_sla.push("Achieved")
      : data.first_response_sla.push("Breached");
  }
};

export const cleanUpData = (data, updatedData) => {
  const propertiesToClean = ["teams", "sub_teams", "status", "sla_status"];
  propertiesToClean.forEach((prop) => {
    if (data[prop]?.length === 0) {
      delete updatedData[prop];
    }
  });

  if (!data.closedDate.From && !data.closedDate.To) {
    delete updatedData.closedDate;
  }
  if (
    !data.date["Date created"].From &&
    !data.date["Date created"].To &&
    !data.date["Date due"].From &&
    !data.date["Date due"].To
  ) {
    delete updatedData.date;
  }
};

const convertStringToObject = (queryString) => {
  const urlParams = new URLSearchParams(queryString);
  const data = {
    "closed tickets": false,
    "open tickets": false,
    teams: [],
    sub_teams: [],
    status: [],
    sla_status: [],
    closedDate: { From: "", To: "" },
    date: {
      "Date created": { From: "", To: "" },
      "Date due": { From: "", To: "" },
    },
    first_response_sla: [],
  };

  for (const [key, value] of urlParams.entries()) {
    switch (true) {
      case key.startsWith("teams") ||
        key.startsWith("sub_teams") ||
        key.startsWith("assignees") ||
        key.startsWith("status") ||
        key.startsWith("sla_status"):
        parseAndSetData(key, value, data);
        break;
      case key.includes("date"):
        parseDateRanges(key, value, data);
        break;
      case key.startsWith("ticket_type"):
        parseTicketTypes(key, value, data);
        break;
      case key.startsWith("first_response_sla"):
        parseTicketTypes(key, value, data);
        break;
      default:
        data[key] = value.split(",");
    }
  }

  const updatedData = { ...data };

  cleanUpData(data, updatedData);

  return updatedData;
};

const convertStringToObjectDashboard = (queryString) => {
  const urlParams = new URLSearchParams(queryString);

  const data = {
    teams: [],
    sub_teams: [],
    "Custom date": {
      From: "",
      To: "",
    },
    Today: {
      From: "",
      To: "",
    },
    Yesterday: {
      From: "",
      To: "",
    },
    "Last 7 days": {
      From: "",
      To: "",
    },
    "Last 14 days": {
      From: "",
      To: "",
    },
    "Last 30 days": {
      From: "",
      To: "",
    },
    spanKey: "Last 7 days",
    startDate: findDashboardDateValue("Last 7 days"),
    endDate: "",
  };

  for (const [key, value] of urlParams.entries()) {
    switch (true) {
      case key.startsWith("teams"):
        data.teams.push(parseInt(value, 10));
        break;
      case key.startsWith("sub_teams"):
        data.sub_teams.push(value);
        break;
      case key.startsWith("today"):
      case key.startsWith("yesterday"):
      case key.startsWith("last_14_days"):
      case key.startsWith("last_30_days"):
      case key.startsWith("custom_date"):
      case key.startsWith("last_7_days"):
        parseDateRangesDashboard(key, value, data);
        break;

      default:
        data[key] = value.split(",");
    }
  }

  const updatedData = { ...data };

  if (data.teams.length === 0) delete updatedData.teams;
  if (data.sub_teams.length === 0) delete updatedData.sub_teams;

  return updatedData;
};

const useURLInfo = () => {
  return convertStringToObject(window.location.search);
};

const useURLInfoDashboard = () => {
  return convertStringToObjectDashboard(window.location.search);
};

export { useURLInfo, useURLInfoDashboard, findIfNumericValue, completedDate };

import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import NestedListContent from "../NestedListContent";
import { SaveCancel } from "../SaveCancel";
import ClickupSymbolLogo from "../../assets/ClickupSymbolLogo.png";
import "./style.css";

const NestedList = ({
  displayMode,
  onSelectList,
  spaces,
  folders,
  lists,
  listsWithoutFolder,
  fetchSpaceData,
  fetchFolderData,
  fetchListData,
  fetchListWithoutFolderData,
  handleItemClick,
  openItems,
  disableCondition,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);

  const dropdownRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleListSelect = (list) => {
    setSelectedList((prevSelectedLists) =>
      prevSelectedLists.includes(list.id)
        ? prevSelectedLists?.filter((id) => id !== list.id)
        : [...prevSelectedLists, list.id]
    );
    onSelectList(list);
    setSaveButtonStatus(true);
    if (displayMode === "dropdown") handleClose();
  };

  const handleSave = () => {
    onSelectList(selectedList);
    handleClose();
    setSelectedList([]);
  };

  const handleCancel = () => {
    setSelectedList([]);
    handleClose();
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleClose();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [open]);

  return (
    <>
      <Button
        onClick={handleOpen}
        disabled={disableCondition}
        sx={{
          position: "absolute",
          top: "4%",
          right: "0%",
        }}
        className={disableCondition ? "disabledButton" : ""}
      >
        <img src={ClickupSymbolLogo} alt={"link"} className="clickupLogo" />
      </Button>

      {/* Modal Mode */}
      {displayMode == "modal" && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            overflow: "hidden",
          }}
        >
          <div className="list">
            <NestedListContent
              spaces={spaces}
              folders={folders}
              lists={lists}
              listsWithoutFolder={listsWithoutFolder}
              fetchSpaceData={fetchSpaceData}
              fetchFolderData={fetchFolderData}
              fetchListData={fetchListData}
              fetchListWithoutFolderData={fetchListWithoutFolderData}
              handleItemClick={handleItemClick}
              openItems={openItems}
              selectedList={selectedList}
              handleListSelect={handleListSelect}
              displayMode={displayMode}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <SaveCancel
                saveButtonStatus={saveButtonStatus}
                handleUserSave={handleSave}
                handleUserCancel={handleCancel}
              />
            </div>
          </div>
        </Modal>
      )}

      {/* Dropdown Mode */}
      {displayMode == "dropdown" && open && (
        <div
          ref={dropdownRef}
          className="dropdown-container"
          style={{
            position: "absolute",
            backgroundColor: "white",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
            borderRadius: "4px",
            overflowY: "scroll",
            scrollbarWidth: "thin",
            width: "100%",
            marginTop: "3rem",
          }}
        >
          <NestedListContent
            spaces={spaces}
            folders={folders}
            lists={lists}
            listsWithoutFolder={listsWithoutFolder}
            fetchSpaceData={fetchSpaceData}
            fetchFolderData={fetchFolderData}
            fetchListData={fetchListData}
            fetchListWithoutFolderData={fetchListWithoutFolderData}
            handleItemClick={handleItemClick}
            openItems={openItems}
            selectedList={selectedList}
            handleListSelect={handleListSelect}
            displayMode={displayMode}
          />
        </div>
      )}
    </>
  );
};

export default NestedList;

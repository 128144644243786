import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import RocketIcon from "@mui/icons-material/Rocket";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ListIcon from "@mui/icons-material/List";
import Skeleton from "@mui/material/Skeleton";
import "./style.css";

export const DynamicList = ({
  renderItem,
  sx,
  items,
  fetchItems,
  handleItemClick,
  openItems,
  dataType,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    await fetchItems();
    setIsLoading(false);
  };

  const getIcon = (type) => {
    switch (type) {
      case "spaces":
        return <RocketIcon />;
      case "folders":
        return <FolderOpenIcon />;
      case "list":
        return <ListIcon />;
      default:
        return <ArrowForwardIosSharpIcon />;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? (
    <div className="loader">
      <Skeleton
        role="progress"
        animation="wave"
        sx={{
          height: "3rem",
        }}
      />
    </div>
  ) : (
    <List component="div" disablePadding sx={sx}>
      {items.map((item) =>
        dataType == "lists_without_folder" || dataType == "lists" ? (
          renderItem(item)
        ) : (
          <div key={item.id}>
            <ListItemButton
              onClick={() => handleItemClick(item.id)}
              sx={{
                py: "0.1rem",
              }}
            >
              {openItems[item.id] ? (
                <ArrowForwardIosSharpIcon fontSize="0.9rem" />
              ) : (
                getIcon(dataType)
              )}
              <ListItemText primary={item.name} sx={{ marginLeft: "0.5rem" }} />
            </ListItemButton>
            <Collapse
              in={openItems[item.id]}
              key={item.id}
              timeout="auto"
              unmountOnExit
            >
              {renderItem(item)}
            </Collapse>
          </div>
        )
      )}
    </List>
  );
};

export const RenderList = ({ onClick, selectedList, list }) => {
  return (
    <List component="div" disablePadding key={list.id}>
      <ListItemButton
        onClick={onClick}
        sx={{
          paddingBottom: "0",
          paddingTop: "0",
          borderRadius: "6px",
          backgroundColor: selectedList[0] === list?.id ? "#e5e4fc" : "inherit",
          "&:hover": {
            backgroundColor:
              selectedList[0] === list?.id ? "#e5e4fc" : "inherit",
          },
        }}
      >
        <ListIcon />
        <ListItemText primary={list.name} />
      </ListItemButton>
    </List>
  );
};

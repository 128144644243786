import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import AddEditAutomationPage from "../../components/AddEditAutomationPage";
import {
  isManagerOrSuperUser,
  isGuardianUser,
} from "../../utils/common_functions";
import { ErrorComponent } from "../Error";
import {
  successNotify,
  errorNotifyList,
} from "../../components/ToastComponent";
import { response_messages } from "./message";
import {
  getSpaceData,
  getFolderData,
  getListData,
  getListWithoutFolderData,
} from "../../services/apiServices";
import {
  AUTOMATIONS_API_PATH as automationBaseURL,
  REACT_APP_API_PATH as baseURL,
} from "../../config";
import {
  customAPICallWithPayload,
  customFetch,
} from "../../services/requestServices";

const successNotifyandRedirect = (response_message) => {
  successNotify(response_message);
  setTimeout(() => {
    window.history.back();
  }, 2000);
};

const AddEditAutomation = () => {
  const locationObject = new URLSearchParams(window.location.search);
  const receivedValue = locationObject.get("rule");
  const [isLoading, setIsLoading] = useState(false);

  const rulesDataUrl = `${automationBaseURL}rules/${receivedValue}/`;
  const triggerRulesDataUrl = `${automationBaseURL}trigger-rules/`;
  const actionRulesDataUrl = `${automationBaseURL}action-rules/`;
  const rulesCreateUrl = `${automationBaseURL}rules/`;

  const [rowData, setRowData] = useState([]);
  const [triggerRulesData, setTriggerRulesData] = useState([]);
  const [actionRulesData, setActionRulesData] = useState([]);

  const [spaces, setSpaces] = useState([]);
  const [folders, setFolders] = useState([]);
  const [lists, setLists] = useState([]);
  const [listsWithoutFolder, setListsWithoutFolder] = useState([]);
  const [openItems, setOpenItems] = useState({});

  const fetchRulesData = async () => {
    setIsLoading(true);

    if (receivedValue == null) setRowData([""]);
    else setRowData(await customFetch(rulesDataUrl, "GET"));

    setIsLoading(false);
  };

  const fetchTriggerRulesData = async () => {
    setIsLoading(true);

    const response = await customFetch(triggerRulesDataUrl, "GET");
    setTriggerRulesData(response);
    setIsLoading(false);
  };

  const fetchActionRulesData = async () => {
    setIsLoading(true);

    const response = await customFetch(actionRulesDataUrl, "GET");
    setActionRulesData(response);
    setIsLoading(false);
  };

  const sendRulesData = async (rulesFormData) => {
    setIsLoading(true);

    const method = receivedValue == null ? "POST" : "PUT";
    const url = method === "POST" ? rulesCreateUrl : rulesDataUrl;

    const response = await customAPICallWithPayload(url, method, rulesFormData);

    if (response === "success") {
      successNotifyandRedirect(response_messages[method]);
    } else {
      errorNotifyList(response);
    }

    setIsLoading(false);
  };

  const fetchSpaceData = async () => {
    const response = await getSpaceData(baseURL);

    setSpaces(response);

    return response;
  };

  const fetchFolderData = async (spaceId) => {
    const response = await getFolderData(spaceId, baseURL);

    setFolders((prevFolders) => ({ ...prevFolders, [spaceId]: response }));
    return response;
  };

  const fetchListWithoutFolderData = async (spaceId) => {
    const response = await getListWithoutFolderData(spaceId, baseURL);

    setListsWithoutFolder((prevListsWithoutFolder) => ({
      ...prevListsWithoutFolder,
      [spaceId]: response,
    }));
    return response;
  };
  
  const fetchListData = async (folderId) => {
    const response = await getListData(folderId, baseURL);

    setLists((prevLists) => ({ ...prevLists, [folderId]: response }));
    return response;
  };

  const handleItemClick = (itemId) => {
    setOpenItems({ ...openItems, [itemId]: !openItems[itemId] });
  };

  const redirectToAutomation = () => {
    window.location.href = "/automations";
  };

  useEffect(() => {
    fetchRulesData();
    fetchTriggerRulesData();
    fetchActionRulesData();
    setIsLoading(false);
  }, [isLoading]);
  return (
    <>
      {isManagerOrSuperUser() || isGuardianUser() ? (
        <AddEditAutomationPage
          isLoading={isLoading}
          pageId={receivedValue}
          rulesTableData={rowData}
          triggerRulesData={triggerRulesData}
          actionRulesData={actionRulesData}
          spaces={spaces}
          folders={folders}
          lists={lists}
          listsWithoutFolder={listsWithoutFolder}
          sendRulesData={sendRulesData}
          fetchSpaceData={fetchSpaceData}
          fetchFolderData={fetchFolderData}
          fetchListData={fetchListData}
          fetchListWithoutFolderData={fetchListWithoutFolderData}
          handleItemClick={handleItemClick}
          openItems={openItems}
        />
      ) : (
        <ErrorComponent
          errorMessage="You do not have access to view this page"
          handleRefresh={redirectToAutomation}
          firstButtonText="Go back"
        />
      )}
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default AddEditAutomation;

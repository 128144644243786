import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import NestedList from "../NestedList";
import FormControl from "@mui/material/FormControl";

export const ClickUpList = ({
  displayMode,
  access_type,
  spaces,
  folders,
  lists,
  listsWithoutFolder,
  fetchSpaceData,
  fetchFolderData,
  fetchListData,
  fetchListWithoutFolderData,
  handleItemClick,
  openItems,
  customID,
  setClickupList,
  component,
  textAlign,
  displayValue,
}) => {
  const [selectedListId, setSelectedListId] = useState(customID);

  useEffect(() => {
    setSelectedListId(customID);
  }, [customID]);

  const handleListSelect = (list) => {
    setSelectedListId(list);
    setClickupList(list);
  };

  const handleManualListIdChange = (e) => {
    setSelectedListId(e.target.value);
  };

  let disableCondition = ["edit", "view"].includes(access_type);
  return (
    <Grid
      sx={{ color: "#3C3E49", fontSize: "14px", alignItems: "center" }}
      container
    >
      {component == "teams_form" ? (
        <Grid xs={10} item sx={{ lineHeight: "4.5rem" }}>
          <span className="mandatoryAsterik">*</span>ClickUp list id
        </Grid>
      ) : (
        <></>
      )}
      <Grid
        xs={component == "teams_form" ? 2 : 12}
        item
        sx={{ textAlign: textAlign }}
      >
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <TextField
            id="outlined-basic"
            variant="outlined"
            disabled={disableCondition}
            data-testid="outlined-basic"
            sx={{
              "& .MuiOutlinedInput-root": {
                maxHeight: "36px !important",
                fontSize: "14px",
                color: "#3c3e49",
              },
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
            }}
            value={selectedListId || ""}
            type={displayValue === "id" ? "number" : "text"}
            onChange={handleManualListIdChange}
            placeholder={displayValue === "id" ? "" : "Select clickup list"}
          />
          <NestedList
            displayMode={displayMode}
            onSelectList={handleListSelect}
            spaces={spaces}
            folders={folders}
            lists={lists}
            listsWithoutFolder={listsWithoutFolder}
            fetchSpaceData={fetchSpaceData}
            fetchFolderData={fetchFolderData}
            fetchListData={fetchListData}
            fetchListWithoutFolderData={fetchListWithoutFolderData}
            handleItemClick={handleItemClick}
            openItems={openItems}
            disableCondition={disableCondition}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

import { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import SearchField from "../Search";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import { Divider, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import { isGuardianUser } from "../../utils/common_functions";
import { WarningModal } from "../WarningModal";
import {
  ChevronRightOutlined,
  IndeterminateCheckBoxSharp,
} from "@mui/icons-material";
import NestedListContent from "../NestedListContent";
import { CircularLoader } from "../CircularLoader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

const IOSSwitch = styled(({ handleEnableStatus, ...props }) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    {...props}
    onChange={handleEnableStatus}
  />
))(({ theme }) => ({
  width: 44,
  height: 22,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    marginLeft: 7,
    marginTop: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#00E676",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

const Rule = ({ row }) => {
  return (
    <>
      <h3
        style={{
          marginBottom: "0.8rem",
        }}
      >
        {row.title}
      </h3>
      <p
        style={{
          color: "#676B7E",
          lineHeight: "2rem",
        }}
      >
        When{" "}
        <span
          style={{
            border: "1px solid #C0C3CE",
            borderRadius: "0.2rem",
            padding: "0.3rem",
          }}
        >
          {row.trigger_rule?.name}
        </span>{" "}
        {row.trigger_rule?.name.includes("is") ? "" : "is"}{" "}
        {row?.trigger_values?.length > 0 &&
          row.trigger_values.map((item, index) => (
            <>
              <span
                key={IndeterminateCheckBoxSharp}
                style={{
                  border: "1px solid #C0C3CE",
                  borderRadius: "0.2rem",
                  padding: "0.3rem",
                }}
              >
                {item}
              </span>
              {index < row.trigger_values.length - 1 && " and "}
            </>
          ))}{" "}
        then{" "}
        <span
          style={{
            border: "1px solid #C0C3CE",
            borderRadius: "0.2rem",
            padding: "0.3rem",
          }}
        >
          {row.action_rule?.name}
        </span>{" "}
        {row.action_rule?.name.includes("to") ? "" : "to"}{" "}
        {row?.action_values?.length > 0 &&
          row.action_values.map((item, index) => (
            <>
              <span
                key={item.id}
                style={{
                  border: "1px solid #C0C3CE",
                  borderRadius: "0.2rem",
                  padding: "0.3rem",
                  marginLeft: index > 0 ? "0.5rem" : "0",
                }}
              >
                {item}
              </span>
              {index < row.action_values.length - 1 && " and"}
            </>
          ))}{" "}
      </p>
    </>
  );
};

const EnabledList = ({ row }) => {
  const totalLists = row.enable_on_list;
  const maxVisible = 2;
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const visibleItems = totalLists?.slice(0, maxVisible);
  const remainingCount = totalLists?.length - maxVisible;

  const handleTooltipOpen = () => setIsTooltipOpen(true);
  const handleTooltipClose = () => setIsTooltipOpen(false);

  return (
    <Grid
      item
      style={{
        fontFamily: "sans-serif",
      }}
    >
      {visibleItems?.map((list) => (
        <Typography key={list.id} variant="body2">
          {list.name}
        </Typography>
      ))}
      {remainingCount > 0 && (
        <Tooltip
          title={
            <div>
              {totalLists.slice(maxVisible).map((list) => (
                <Typography key={list.id} variant="body2">
                  {list.name}
                </Typography>
              ))}
            </div>
          }
          open={isTooltipOpen}
          onOpen={handleTooltipOpen}
          onClose={handleTooltipClose}
          arrow
          placement="bottom-start"
        >
          <Typography
            variant="body2"
            style={{
              color: "#651FFF",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            +{remainingCount} more
          </Typography>
        </Tooltip>
      )}
    </Grid>
  );
};

const MenuButtonIcon = ({
  row,
  handleEditAutomation,
  handleDeleteAutomation,
  handleEnableList,
  spaces,
  folders,
  lists,
  listsWithoutFolder,
  fetchSpaceData,
  fetchFolderData,
  fetchListData,
  fetchListWithoutFolderData,
  handleItemClick,
  openItems,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [nestedAnchorEl, setNestedAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openNestedMenu = Boolean(nestedAnchorEl);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectMultipleList, setSelectMultipleList] = useState([]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setNestedAnchorEl(null);
  };

  const toggleEnableOn = (event) => {
    setNestedAnchorEl(event.currentTarget);
  };

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  const handleMultipleClickupList = (list) => {
    setSelectMultipleList((prevList) => {
      const updatedList = prevList.includes(list)
        ? prevList.filter((item) => item !== list)
        : [...prevList, list];

      handleEnableList(row.id, updatedList);

      return updatedList;
    });
  };

  useEffect(() => {
    if (row.enable_on_list && row.enable_on_list.length > 0) {
      const updatedList = row.enable_on_list.map((item) => ({
        id: item.id,
        name: item.name,
      }));

      setSelectMultipleList(updatedList);
    }
  }, [row.enable_on_list?.length]);

  return (
    <>
      <IconButton
        className="actionButtonMenu"
        onClick={handleMenu}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        sx={{
          "& .MuiMenu-paper": {
            minHeight: "2.3rem",
            overflow: "hidden",
            borderRadius: "10px",
            width: "9rem",
          },
          "& .MuiMenu-list": {
            padding: 0,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleEditAutomation(row.id, "edit", row.clickup_list_id);
            handleCloseMenu();
          }}
          sx={{
            padding: "3px",
            fontSize: "14px",
            "&:hover": {
              backgroundColor: "#f3ebff",
              cursor: "pointer",
            },
          }}
        >
          <IconButton>
            <EditOutlinedIcon />
          </IconButton>
          Edit
        </MenuItem>

        <MenuItem
          sx={{
            padding: "3px",
            "&:hover": {
              backgroundColor: "#f3ebff",
              cursor: "pointer",
            },
          }}
        >
          <WarningModal
            styleContainer={{ padding: "0", width: "auto" }}
            open={openDeleteModal}
            title="Delete"
            mainButtonColor={" var(--Interactive-Bg-Err, #FF8A80)"}
            src={
              <IconButton
                onClick={handleOpenDeleteModal}
                sx={{
                  "&:hover": {
                    backgroundColor: "#f3ebff",
                    cursor: "pointer",
                  },
                }}
              >
                <DeleteOutlinedIcon />
                <div
                  style={{
                    fontSize: "14px",
                    color: "#000000de",
                    marginLeft: "0.5rem",
                  }}
                >
                  Delete
                </div>
              </IconButton>
            }
            displayMessage={
              <>
                Do you want to delete <strong>{row.title}</strong>? <br />
                This action can’t be undone.
              </>
            }
            closeButtonTitle="Cancel"
            functionalButtonTitle="Delete"
            handleConfirm={() => {
              handleDeleteAutomation(row.id, row.title);
              handleClose();
            }}
            handleClose={handleClose}
          />
        </MenuItem>

        <MenuItem
          onClick={toggleEnableOn}
          sx={{
            padding: "3px",
            fontSize: "14px",
            "&:hover": {
              backgroundColor: "#f3ebff",
              cursor: "pointer",
            },
          }}
        >
          <IconButton>
            <ListOutlinedIcon />
          </IconButton>
          Enable on
          <ChevronRightOutlined
            fontSize="small"
            sx={{
              color: "rgba(0, 0, 0, 0.54)",
              marginLeft: "1rem",
            }}
          />
        </MenuItem>

        <Menu
          anchorEl={nestedAnchorEl}
          open={openNestedMenu}
          onClose={() => setNestedAnchorEl(null)}
          sx={{
            "& .MuiMenu-paper": {
              minHeight: "2.3rem",
              minWidth: "18rem",
              width: "20rem",
              height: "18rem",
              left: "calc(100% - 31rem) !important",
              borderRadius: "10px",
              position: "absolute",
              overflow: "auto",
              scrollbarWidth: "none",
              scrollbarColor: "#AFB2C0 white",
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            sx={{
              padding: "0",
              backgroundColor: "white !important",
            }}
          >
            <NestedListContent
              spaces={spaces}
              folders={folders}
              lists={lists}
              listsWithoutFolder={listsWithoutFolder}
              fetchSpaceData={fetchSpaceData}
              fetchFolderData={fetchFolderData}
              fetchListData={fetchListData}
              fetchListWithoutFolderData={fetchListWithoutFolderData}
              handleItemClick={handleItemClick}
              openItems={openItems}
              selectedList={selectMultipleList}
              handleListSelect={handleMultipleClickupList}
              handleEnableList={handleEnableList}
              displayMode="checkbox"
            />
          </MenuItem>
        </Menu>
      </Menu>
    </>
  );
};

const AutomationsTable = ({
  rulesData,
  isLoading,
  spaces,
  folders,
  lists,
  listsWithoutFolder,
  fetchRulesData,
  deleteRule,
  updatePriority,
  updateEnableStatus,
  enableList,
  fetchSpaceData,
  fetchFolderData,
  fetchListData,
  fetchListWithoutFolderData,
  handleItemClick,
  openItems,
}) => {
  const [value, setValue] = useState("active");
  const [data, setData] = useState(rulesData);

  const filteredData = useMemo(() => {
    return data.filter((row) =>
      value === "active" ? row.enable : !row.enable
    );
  }, [data, value]);

  const handleEditAutomation = (id) => {
    window.location.href = `/automations/edit-automation/?rule=${id}`;
  };

  const handleAddAutomation = () => {
    window.location.href = "/automations/add-automation";
  };

  const handleDeleteAutomation = (id, ruleName) => {
    deleteRule(id, ruleName);
  };

  const handlePriorityUpdate = (priority_from, priority_to) => {
    updatePriority(priority_from, priority_to);
  };

  const handleEnableStatus = (id, enableStatus) => {
    updateEnableStatus(id, enableStatus);
  };

  const handleEnableList = (id, enabledList) => {
    enableList(id, enabledList);
  };

  const columns = useMemo(() => [
    {
      accessorKey: "priority",
      header: "Priority",
      size: "100",
    },
    {
      header: "Rule",
      accessorFn: (row) => <Rule row={row} />,
      size: "350",
    },
    {
      header: "Enabled List",
      accessorFn: (row) => <EnabledList row={row} />,
    },
    {
      accessorKey: "updatedBy",
      header: "Updated By",
      size: "100",
    },
    {
      accessorKey: "enable",
      header: "",

      Cell: (cell) => (
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <IOSSwitch
            sx={{ m: 1 }}
            key={cell.row.original.id}
            defaultChecked={cell.row.original.enable}
            handleEnableStatus={() =>
              handleEnableStatus(
                cell.row.original.id,
                !cell.row.original.enable
              )
            }
          />
          <MenuButtonIcon
            row={cell.row.original}
            handleEditAutomation={handleEditAutomation}
            handleDeleteAutomation={handleDeleteAutomation}
            handleEnableList={handleEnableList}
            spaces={spaces}
            folders={folders}
            lists={lists}
            listsWithoutFolder={listsWithoutFolder}
            fetchSpaceData={fetchSpaceData}
            fetchFolderData={fetchFolderData}
            fetchListData={fetchListData}
            fetchListWithoutFolderData={fetchListWithoutFolderData}
            handleItemClick={handleItemClick}
            openItems={openItems}
          />
        </Grid>
      ),
      size: "100",
    },
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleScroll = (e) => {
    const clientHeight = e.target.clientHeight;
    const scrollTop = e.target.scrollTop;
    const scrollHeight = e.target.scrollHeight;
    const sum = Math.trunc(clientHeight + scrollTop - scrollHeight);
    if (sum >= -10 && isLoading === false) {
      fetchRulesData();
    }
  };

  const updateLocalData = (priorityFrom, priorityTo) => {
    const updatedData = [...data];
    const draggingIndex = updatedData.findIndex(
      (item) => item.priority === priorityFrom
    );
    const hoveredIndex = updatedData.findIndex(
      (item) => item.priority === priorityTo
    );

    if (draggingIndex !== -1 && hoveredIndex !== -1) {
      const [movedItem] = updatedData.splice(draggingIndex, 1);
      updatedData.splice(hoveredIndex, 0, movedItem);
      setData(updatedData);
    }
  };

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data: filteredData,
    enableRowOrdering: true,
    enableRowDragging: true,
    enableSorting: false,
    enableFilters: false,
    enableStickyHeader: true,
    manualPagination: true,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    renderEmptyRowsFallback: () => {
      const getMessage = () => {
        if (isLoading) {
          return <CircularLoader />;
        }
        return value === "active"
          ? "No active automation rules found"
          : "No automation rules found";
      };

      return <div className="loadingDiv">{getMessage()}</div>;
    },

    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          const draggingItem = data.find(
            (item) => item.priority === draggingRow.original.priority
          );
          const hoveredItem = data.find(
            (item) => item.priority === hoveredRow.original.priority
          );

          if (draggingItem && hoveredItem) {
            const priorityFrom = draggingItem.priority;
            const priorityTo = hoveredItem.priority;

            handlePriorityUpdate(priorityFrom, priorityTo);
            updateLocalData(priorityFrom, priorityTo);
          }
        }
      },
    }),

    muiTableContainerProps: {
      sx: {
        maxHeight: "88vh",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      },
      onScroll: (event) => handleScroll(event),
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: "5px",
        border: "1px solid #e0e0e0",
      },
    },
    displayColumnDefOptions: {
      "mrt-row-drag": {
        size: 20,
        header: "",
      },
    },

    muiTableHeadCellProps: {
      sx: {
        fontWeight: 500,
        fontSize: "12px",
        fontFamily: "Rubik",

        color: "#3c3e49",
        backgroundColor: "#f5f5f5",

        "& .Mui-TableHeadCell-Content": {
          paddingTop: "10px",
        },
      },
    },
    muiTableBodyRowProps: () => ({
      hover: false,
      sx: {
        td: {
          fontSize: "12px",
          fontFamily: "Rubik",
        },
      },
    }),
  });

  useEffect(() => {
    setData(rulesData);
  }, [rulesData, isLoading]);

  return (
    <Grid
      sx={{
        width: "95%",
        maxHeight: "99vh",
        backgroundColor: "transparent",
        paddingLeft: "1em",
        overflow: "hidden",
        paddingRight: "1.25px",
      }}
    >
      <Grid
        container
        spacing={2}
        style={{
          alignItems: "center",
        }}
      >
        <Grid item className="teamsHeading" xs={6}>
          Automations
        </Grid>

        <Grid
          item
          container
          xs={6}
          sx={{
            justifyContent: "end",
          }}
        >
          <Grid item>
            <SearchField
              heading={<span>automation</span>}
              buttonDisplay={isGuardianUser() ? "" : "none"}
              buttonTitle="Add automation"
              handleAdd={handleAddAutomation}
            />
          </Grid>
        </Grid>
      </Grid>

      <Tabs
        value={value}
        onChange={handleChange}
        textColor="inherit"
        indicatorColor="secondary"
        sx={{
          height: "1rem",
          "& .MuiTabs-indicator": {
            backgroundColor: "#651FFF",
            fontFamily: "Rubik !important",
          },

          "& .Mui-selected": {
            color: "#651FFF",
            fontWeight: 600,
          },
        }}
      >
        <Tab
          value="active"
          icon={
            <CircleIcon
              sx={{
                width: 16,
                color: "#00E676",
              }}
            />
          }
          iconPosition="start"
          label="Active"
          sx={{
            textTransform: "none",
            paddingBottom: "1rem",
            fontSize: "1rem",
            backgroundColor: "#ffffff !important",
            fontFamily: "Rubik",
          }}
        />
        <Tab
          value="inactive"
          icon={
            <CircleIcon
              sx={{
                width: 16,
                color: "#C0C3CE",
              }}
            />
          }
          iconPosition="start"
          label="Inactive"
          sx={{
            textTransform: "none",
            paddingBottom: "1rem",
            fontSize: "1rem",
            fontFamily: "Rubik",
            backgroundColor: "#ffffff !important",
          }}
        />
      </Tabs>
      <Divider
        style={{
          marginBottom: "1.5rem",
        }}
      />

      <TabPanel
        value={value}
        index={0}
        sx={{
          padding: "0",
        }}
      >
        <MaterialReactTable table={table} />
      </TabPanel>
    </Grid>
  );
};

export default AutomationsTable;

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export const SimpleTable = ({
  rows,
  columns,
  headerStyle,
  rowStyle,
  size,
  tablesStyle,
  tableContainerStyle,
}) => {
  return (
    <TableContainer style={tableContainerStyle}>
      <Table
        stickyHeader
        aria-label="sticky table"
        sx={tablesStyle}
        size={size}
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.label} style={headerStyle}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              {columns.map((column) => (
                <TableCell key={row[column.id]} style={rowStyle}>
                  {column.label === "Team | Squad" ? (
                    <a
                      href={row.calendar_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {row[column.id]}
                    </a>
                  ) : (
                    row[column.id]
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
